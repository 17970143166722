@import '~@neutron/core/dist/neutron/neutron.css';
//remove before committing
body > iframe {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

Link {
  color: inherit;
  text-decoration: none;
}

.dropdown-wrapper:after {
  content: '▼';
  font-size: 0.65rem;
  top: 8px;
  right: 10px;
  position: absolute;
}

.neu-select-native__icon.sc-neu-select-native {
  z-index: 5;
}

.neu-avatar--initials {
  width: 1.375rem;
  height: 1.375rem;
}

.neu-avatar--initials[color='gray-10'] {
  width: 2rem;
  height: 2rem;
}

.neu-sub-left-nav {
  width: -webkit-fill-available;
  max-width: 196px;
  height: auto !important;
  min-height: 100%;
}

.neu-sub-left-nav--card {
  width: -webkit-fill-available !important;
  background-color: white !important;
  text-align: left !important;
}

.neu-list template {
  height: 100%;
}

// TODO: Make the nav list smaller
// .neu-tablist {
//   height: 3.75rem;
// }

.neu-item {
  --background-activated-opacity: 0.5;
  --background-focused-opacity: 0.5;
  --background-hover-opacity: 0.5;
  --background-hover: #f6f6f6;
  overflow: inherit;
}

.neu-paginator {
  margin-bottom: 0.75rem;
  margin-right: 0.75rem;
}

.neu-table {
  height: calc(100% - 70px);
  overflow: auto;
  position: relative;
}

.neu-table-body {
  height: calc(100% - 106px);
  scroll-behavior: smooth;
}

.neu-table-row {
  align-items: start;
}

.admin-areas-unit-table {
  height: 100%;
}

path.slice {
  stroke-width: 2px;
}

.show-more-text {
  color: blue;
}

.show-more-text:hover {
  text-decoration: underline;
  cursor: pointer;
}
.center-content {
  &__row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-justify-content: space-between;
  }

  &__col {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: space-between;

    &--top-spacing {
      padding: 10% 0;
    }
  }
}
