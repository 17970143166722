.App {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.break-all {
  word-break: break-all;
}

.invisible {
  visibility: hidden;
}

.hover-card {
  &:hover {
    animation-name: scaleAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    transition-timing-function: linear;
    transition-delay: 0.2s;
    transition-property: color;
  }
  &__disabled {
    background-color: var(
      --neu-color-gray-10,
      var(--neu-color-gray-10, #e2e2e2)
    ) !important;
  }
  &__issue {
    &:hover {
      background-color: rgba(32, 117, 173, 0.1) !important;
    }
  }
}

.dropdown-item {
  font-size: 0.95rem !important;
  font-weight: 500;
  color: #686868 !important;
}

.dropdown-item,
.dropdown-hover-item {
  padding: 0.6rem 1rem;
  text-align: left !important;
}

.dropdown-hover-item {
  cursor: pointer;
  font-size: 0.95rem !important;
}

.dropdown-search-no-result {
  position: relative;
  padding: 0.8rem 1.6rem;
  font-size: 1rem;
  text-align: left;
}

.dropdown-hover-item.checkable {
  padding-right: calc(1.6rem + 20px) !important;
}

.dropdown-hover-item.selected {
  background-color: var(--neu-color-gray-10, var(--neu-color-gray-10, #e2e2e2));
}

.dropdown-hover-item:active,
.dropdown-hover-item:hover {
  background-color: var(--neu-color-gray-10, var(--neu-color-gray-10, #e2e2e2));
}

.custom-checkmark {
  position: absolute;
  right: 20px;
}

.required-content:before {
  content: '*';
  position: absolute;
  color: #ce3637; // red-60
  font-size: 1.4rem;
  top: 1.5rem;
}

.text-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  &__1 {
    -webkit-line-clamp: 1;
  }
  &__2 {
    -webkit-line-clamp: 2;
  }
  &__3 {
    -webkit-line-clamp: 3;
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.01);
  }
}
