.custom-toast-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  width: 100vw;
  z-index: 15;
  background: transparent;
  transition: all 500ms ease;
}

.toast {
  font-size: 0.8rem;
  position: absolute;
  box-sizing: border-box;
  z-index: 20;
  bottom: 100px;
  right: 100px;
  color: #124e7f !important;
  text-align: center;
  animation-timing-function: linear;
  background-color: #ffffff !important;
}

.toast-small-text {
  font-size: 0.8rem;
}

.toast-shadow-primary {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(25, 25, 25, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(25, 25, 25, 0.3);
}

.hidden {
  display: none !important;
}

@keyframes toast-in-alert-bottom {
  0% {
    bottom: -108px;
  }
  20% {
    bottom: -60px;
  }
  40% {
    bottom: -20px;
  }
  60% {
    bottom: 20px;
  }
  80% {
    bottom: 60px;
  }
  100% {
    bottom: 100px;
  }
}

@keyframes toast-out-alert-bottom {
  0% {
    bottom: 100px;
  }
  20% {
    bottom: 60px;
  }
  40% {
    bottom: 20px;
  }
  60% {
    bottom: -20px;
  }
  80% {
    bottom: -60px;
  }
  100% {
    bottom: -108px;
  }
}

@keyframes toast-in-question-bottom {
  0% {
    bottom: -320px;
  }
  20% {
    bottom: -236px;
  }
  40% {
    bottom: -152px;
  }
  60% {
    bottom: -68px;
  }
  80% {
    bottom: 16px;
  }
  100% {
    bottom: 100px;
  }
}

@keyframes toast-out-question-bottom {
  0% {
    bottom: 100px;
  }
  20% {
    bottom: 16px;
  }
  40% {
    bottom: -68px;
  }
  60% {
    bottom: -152px;
  }
  80% {
    bottom: -236px;
  }
  100% {
    bottom: -320px;
  }
}
