.multiselectParent {
  width: 97% !important;
  position: relative;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 5px 15px;
  min-height: 44px;
  background: #ffffff;
  border: 1px solid #939393;
  box-sizing: border-box;

  .multiselectChild {
    flex: 1 1 150px;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    width: 170px;
    flex-wrap: wrap;
    cursor: pointer;

    .selectedItems {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 8px 6px 16px;
      background: #aecde5;
      border: 1px solid #aecde5;
      box-sizing: border-box;
      border-radius: 16px;
      margin: 2px;
      // font-family: Mark for HCA;
      font-family: var(--neu-font-family, 'HCA-Mark', 'Arial', sans-serif);
      font-size: 12px;
      line-height: 20px;
      color: #191919;

      .closeIcon {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: #4b4b4b;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
  .optionsList {
    left: 0px;
    width: 100%;
    background-color: white;
    position: absolute;
    border-radius: 5px;
    height: max-content;
    max-height: 200px;
    overflow-y: scroll !important;
    scroll-behavior: smooth;
    z-index: 999 !important;
    box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.21);

    .optionsItem {
      padding: 10px;
      cursor: pointer;
      font-family: sans-serif;
      box-sizing: border-box;
      text-align: left;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
    }
  }
  .iconArrow {
    position: absolute;
    // right: 0;
    margin-top: 2px;
    cursor: pointer;

    right: 5px;
    top: 50%;
    margin-top: -0.6rem;
    position: absolute;
    z-index: 10;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
    pointer-events: none;
    background-color: transparent;
  }
}
